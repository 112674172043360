<template lang="pug">
.main-wrapper.relatorios-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Relatórios</b>

    Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
        .p-grid.p-fluid
            .p-col
                label.form-label Tipo de relatório:
                ProgressBar(v-if='waiting_tipo_relatorio' mode="indeterminate")
                MultiSelect(
                    v-else
                    v-model='filters.nr_relatorio'
                    :options='options.tiporelatorio_multi'
                    :filter='true'
                    optionLabel='text'
                    optionValue='value'
                    dataKey='value'
                )
            .p-col
                label.form-label Situação:
                ProgressBar(v-if='waiting_tipo_relatorio' mode="indeterminate")
                MultiSelect(
                    v-else
                    v-model='filters.ie_situacao'
                    :options='options.situacaorelatorio_multi'
                    :filter='true'
                    optionLabel='text'
                    optionValue='value'
                    dataKey='value'
                )
        .ta-right
            Button(label='Buscar' @click='applyFilters()')

    .my-2(style='display: flow-root')
        span(style='float: left')(v-show='caixaAnterior' v-tooltip.top="'Voltar para Caixa Supervisão'")
            Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='voltaProCaixa(caixaAnterior)')
        span(style='float: right')
            Button(label='Gerar relatório' icon="jam jam-plus" @click="$router.push('gerar/')")

    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum relatório encontrado.
    div(v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="props")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                    Panel.panel-list.ta-center(:header="props.data.ds_descricao" style='position: relative')
                        .ta-left
                            p <b>Criado:</b> {{ props.data.dt_criacao }}
                            p <b>Descrição:</b> {{ `${props.data.nr_relatorio} - ${props.data.ds_relatorio}` }}
                            p <b>Situação:</b> {{ props.data.ie_situacao.ds_valor }}
                            p <b>Validade:</b> {{ props.data.dt_expiracao }}
                            p <b>Ações:</b>
                                .cell
                                a(v-if='props.data.ds_url_relatorio' :href="props.data.ds_url_relatorio" target="_blank" style='cursor: pointer;')
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Abrir'"
                                        icon="jam jam-arrow-square-up-right"
                                    )

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Relatórios')
            DataTable(:value="list")
                Column(headerStyle='width: 10%;' field='dt_criacao' header='Criado')
                    template(#body='props')
                        .cell
                            span(v-tooltip.top="props.data.dt_expiracao") {{ props.data.dt_criacao }}

                Column(headerStyle='width: 25%;' field='ds_relatorio' header='Descrição')
                    template(#body='props')
                        .cell
                            span(v-tooltip.top="props.data.ds_relatorio") {{ `${props.data.nr_relatorio} - ${props.data.ds_relatorio}` }}

                Column(headerStyle='width: 15%;' field='ie_situacao' header='Situação')
                    template(#body='props')
                        .cell(:class="['bg-color-situacao', props.data.ie_situacao.ie_valor]")
                            i.mr-1(v-if='props.data.ie_situacao.ie_valor == "ER"' class="jam jam-info" style='font-size: 20px;' v-tooltip.top='props.data.ds_erro')
                            span {{ props.data.ie_situacao.ds_valor }}

                Column(headerStyle='width: 10%;' field='dt_expiracao' header='Validade')
                    template(#body='props')
                        .cell
                            span(v-tooltip.top="props.data.dt_expiracao") {{ props.data.dt_expiracao }}

                Column(headerStyle='width: 10%;' field='ds_url_relatorio')
                    template(#header) Ações
                    template(#body='props')
                        .cell
                            a(v-if='props.data.ds_url_relatorio' :href="props.data.ds_url_relatorio" target="_blank" style='cursor: pointer;')
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Abrir'"
                                    icon="jam jam-arrow-square-up-right"
                                )

            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .relatorios-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .p-tooltip {
            max-width: none;
        }
        .bg-color-situacao {
            &.GE { background-color: rgba(0, 255, 0, 0.1) }
            &.PR { background-color: rgba(255, 141, 0, 0.1); }
            &.ER { background-color: rgba(255, 0, 0, 0.1); }
            &.EX { background-color: rgba(0, 0, 0, 0.1); }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import moment from 'moment'

    import { Relatorio, DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    export default {
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown, MultiSelect },
        directives: { tooltip: Tooltip },
        mounted () {
            this.getSituacao()
            this.getTipoRelatorio()
            this.applyFilters()

            setTimeout(this.intervalApplyFilters, this.calcIntervalTime());
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (from.name == "visualizar-supervisao")
                    vm.caixaAnterior = from.params.id
            })
        },
        data () {
            return {
                getListTimeAcresc: 0,
                getListIterationsCount: 0,
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waiting_tipo_relatorio: false,
                waiting_ie_situacao: false,
                filters: {
                    nr_relatorio: [],
                    ie_situacao: [],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                options: {},
                caixaAnterior: null
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                return Relatorio.getFilter(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results

                        response.data.results.forEach(result => {
                            result.dt_criacao = moment(result.dt_criacao).format('DD/MM/YYYY HH:mm')
                            result.dt_expiracao = moment(result.dt_expiracao).format('DD/MM/YYYY HH:mm')
                        })
                    }
                    this.waiting = false
                    return true
                })
            },
            getTipoRelatorio () {
                this.waiting_tipo_relatorio = true
                Relatorio.getList().then(response => {
                    if (response.status == 200) {
                        this.options.tiporelatorio = []
                        response.data.forEach(item => {
                            this.options.tiporelatorio.push({text: item.nr_relatorio + " - " +item.ds_relatorio, value: item.nr_relatorio})
                        })
                        this.options.tiporelatorio_multi = [...this.options.tiporelatorio]
                    }
                    this.waiting_tipo_relatorio = false
                })
            },
            getSituacao () {
                this.waiting_ie_situacao = true
                DominioValor.find('SITUACAO_ITEM').then(response => {
                    if (response.status == 200) {
                        this.options.situacaorelatorio = []
                        response.data.forEach(item => {
                            this.options.situacaorelatorio.push({
                                text: item.ds_valor, value: item.ie_valor
                            })
                            this.options.situacaorelatorio_multi = [...this.options.situacaorelatorio]
                        })
                    }
                    this.waiting_ie_situacao = false
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) {
                    this.$router.replace( { query: { pg: this.paginator.page } } )
                }
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) params[key] = this.filters[key]
                })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            intervalApplyFilters() {
                this.getListTimeAcresc++;
                this.getListIterationsCount++;

                this.applyFilters();

                if (this.getListIterationsCount <= 20)
                    setTimeout(this.intervalApplyFilters, this.calcIntervalTime());
            },
            calcIntervalTime () {
                if(this.getListTimeAcresc >= 10)
                    this.getListTimeAcresc = 0;

                return 60000 + this.getListTimeAcresc * 15000; //Incremento de 15 segundos
            },
            voltaProCaixa(id){
                this.$router.push({name: 'visualizar-supervisao', params: {id: id}})
            }
        }
    }
</script>
